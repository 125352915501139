/**
 * First we will load all of this project's JavaScript dependencies './package'.
 */

import {HelpersAllTap} from './components/helpers';

import * as index from './components/classes/index';

(function (global, factory) {
    'use strict';

    if (typeof (module) === 'object' && typeof (module.exports) === 'object') {
        /**
         *
         * For CommonJS and CommonJS-like environments where a proper `window`
         * is present, execute the factory and get ClassObject.
         * For environments that do not have a `window` with a `document`
         * (such as Node.js), expose a factory as module.exports.
         * This accentuates the need for the creation of a real `window`.
         * e.g. const ClassObject = require('Class')(window);
         * @type {void|(function(*): void)}
         */
        module.exports = global.document ? factory(global, false) : (_window) => {
            if (typeof (_window.document) !== 'undefined') {
                return factory(_window, true);
            } else {
                throw (new Error('Class requires a window with a document'));
            }
        };
    } else {
        factory(global, true);
    }
})(typeof (window) !== 'undefined' ? window : this, function (window, isGlobal) {
    if (isGlobal) {
        window.AllTap = HelpersAllTap({})({window: window});
        window.StageClass = index;
    }
});
